/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/highlight.js@9.12.0/lib/highlight.min.js
 * - /npm/dompurify@2.3.1/dist/purify.min.js
 * - /npm/plyr@3.6.8/dist/plyr.min.js
 * - /npm/autosize@5.0.1/dist/autosize.min.js
 * - /npm/velocity-animate@1.5.2/velocity.min.js
 * - /npm/velocity-ui-pack@1.2.2/velocity.ui.min.js
 * - /npm/apexcharts@3.27.3/dist/apexcharts.min.js
 * - /npm/cropperjs@1.5.12/dist/cropper.min.js
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/jquery.guillotine@1.4.3/js/jquery.guillotine.min.js
 * - /npm/chroma-js@2.1.2/chroma.min.js
 * - /npm/@claviska/jquery-minicolors@2.3.5/jquery.minicolors.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/@suitespot/signature_pad@3.0.0-beta.4/dist/signature_pad.umd.min.js
 * - /npm/humanize-duration@3.27.0/humanize-duration.min.js
 * - /npm/crypto-js@4.1.1/crypto-js.min.js
 * - /npm/slugify@1.6.0/slugify.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
